<template>
  <!-- 单品活动 -->
  <div>
    <div v-if="isShow">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>单品活动</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button type="success" style="height: 36px" @click="reload"
          ><i style="font-size: 18px" class="el-icon-refresh-right"></i
        ></el-button>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding: 50px 20px;
          width: 90%;
        "
      >
        <div
          v-for="activity in activitys"
          :key="activity.id"
          @click="activityShow(activity.id)"
          style="
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            border: 1px solid #e0e3e9;
            border-radius: 20px;
            width: 200px;
            height: 270px;
            margin-left: 40px;
            margin-bottom: 30px;
            cursor: pointer;
          "
        >
          <img
            style="width: 50px; height: 50px; margin-top: 30px"
            :src="activity.pic"
            alt=""
          />
          <p style="font-size: 36px; color: #333; margin: 10px 0">
            {{ activity.title }}
          </p>
          <p style="width: 158px; font-size: 14px; font-family: 'PingFang SC'">
            {{ activity.tip }}
          </p>
        </div>
      </div>
    </div>
    <transition
      enter-active-class="animate__fadeInUpBig"
      leave-active-class="animate__fadeOutUpBig"
    >
      <div v-if="!isShow">
        <active-group
          :breadcrumb="breadcrumb[label]"
          @closeClick="closeClick"
        ></active-group>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    activeGroup: () => import("./childrens/singleItemGroup.vue"),
  },
  data() {
    return {
      isShow: true,
      breadcrumb: [
        "买送活动",
        "买赠活动",
        "首单价活动",
        "单品券活动",
        "买充活动",
      ],
      label: 0,
      activitys: [
        {
          id: 1,
          pic: require("@/assets/mianAssets/label7_assets/买送.png"),
          title: "买送",
          tip: "例：买1送1、买10送1、买20送5",
        },
        {
          id: 2,
          pic: require("@/assets/mianAssets/label7_assets/买赠.png"),
          title: "买赠",
          tip: "例: 买1赠1包鱿鱼干 、买10赠2张10元优惠券 、买20赠5张10元优惠券",
        },
        {
          id: 3,
          pic: require("@/assets/mianAssets/label7_assets/首单价.png"),
          title: "首单价",
          tip: "例: 设置后用户首次购买该商品的价格即是首单价",
        },
        {
          id: 4,
          pic: require("@/assets/mianAssets/label7_assets/单品券.png"),
          title: "单品券",
          tip: "例: 该商品的兑换券（假设设置了小鱼干商品的活动，用户领取该单品券后可在商城免费兑换小鱼干）",
        },
        {
          id: 5,
          pic: require("@/assets/mianAssets/label7_assets/充值.png"),
          title: "VIP",
          tip: "例: 买VIP卡充值1000元升级至银卡会员 、买VIP卡充值2000元升级至金卡会员 、买VIP卡充值5000元升级至黑金会员",
        },
      ],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    reload() {
      location.reload();
    },
    activityShow(i) {
      this.isShow = !this.isShow;
      this.label = --i;
    },
    closeClick() {
      this.isShow = true;
    },
  },
};
</script>
<style scoped>
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
